import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Page } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { EntityListSection, PageDetailsLayout as CoreLayout } from '@shapeable/core';
import { Trend } from '@shapeable/gesda-types';

// -------- Types -------->

export type PageDetailsLayoutProps = Classable & HasChildren & {
  entity: Page & { trends?: Trend[] };
}

export const PageDetailsLayoutDefaultProps: Omit<PageDetailsLayoutProps, 'entity'> = {};

// -------- Styles -------->

const ListSectionStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  ListSection: styled(EntityListSection)`${ListSectionStyles}`,
};

export const PageDetailsLayout: React.FC<PageDetailsLayoutProps> = (props) => {
  const { className, entity, ...rest } = props;
  const { trends = [] } = entity;
  
  return (
    <CoreLayout
      {...rest}
      entity={entity} 
      className={className} 
      asideFooter={
        <My.ListSection items={trends} />
      } 
    />
  )
};

PageDetailsLayout.defaultProps = PageDetailsLayoutDefaultProps;