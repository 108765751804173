import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { EntityTypeName, HorizonType as MyType } from '@shapeable/types';
const gql = String.raw;

export const SentimentType: EntityConfig<MyType> = {
  name: 'SentimentType',
  type: 'taxonomy',
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      color: { type: 'linked', entityTypeName: 'Color' },
      label: { type: 'text' },
    }
  },
  update: {
    queryFields: `
      color { value }
    `
  }
}; 