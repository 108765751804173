import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { EntityTypeName, HorizonType as MyType } from '@shapeable/types';
const gql = String.raw;

export const SentimentObservation: EntityConfig<MyType> = {
  name: 'SentimentObservation',
  type: 'child',
  autoRoutes: ['create', 'update'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
      topics: { type: 'linkedMultiple', entityTypeName: 'Topic' },
      subTopics: { type: 'linkedMultiple', entityTypeName: 'SubTopic' },
    }
  }
}; 