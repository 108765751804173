import { EntityConfig, entityStandardFormFields } from '@shapeable/core';
import { EntityTypeName, HorizonType as MyType } from '@shapeable/types';
const gql = String.raw;

export const SentimentAnalysis: EntityConfig<MyType> = {
  name: 'SentimentAnalysis',
  type: 'child',
  autoRoutes: ['create', 'update'],
  table: {
    fieldNames: ["images", "type", "observations"],
    options: {
      observations: { weight: 5}, 
    }
  },
  form: {
    fieldSets: [
      { name: 'overview' },
      { name: 'observations' },
    ],
    fields: {
      ...entityStandardFormFields,
      type: { type: 'linked', entityTypeName: 'SentimentType' },
      observations: { type: 'linkedTable', entityTypeName: 'SentimentObservation', fieldSet: 'observations' },
      images: { type: 'linkedMultiple', entityTypeName: 'ImageAsset' },
    }
  }
}; 