import * as React from 'react';
import { filter, includes  } from 'lodash';
import { createApplication, WorkstreamTrafficControl, entityConfigs, appSectionConfigs as coreAppSectionConfigs, Icons, DataTableNameValue, DataTable, Content, IconTitle, graphql, FormFieldSet, ENTITY_VALUE_FIELDS } from '@shapeable/core';
import { ApplicationLogo, ApplicationLogoInverted } from './components';
import { themes } from '@shapeable/theme';

import { SentimentAnalysis, SentimentObservation, SentimentType } from './config';
import { PageDetailsLayout } from './components/page-details-layout';
const { Person, FeedEntry, Page, Menu, IndicatorValue, Trend, Topic, SubTopic, Team } = entityConfigs;

const appSectionConfigs = coreAppSectionConfigs;

Page.form.fieldSets = [
  ...filter(Page.form.fieldSets, (fs) => !includes(['connections'], fs.name)),
  { name: 'connections' },
];

// Page.form.fields.trends = { type: 'linkedMultiple', entityTypeName: 'Trend', fieldSet: 'overview', recommended: false };

Page.form.fields.connectedPages = { type: 'linkedMultiple', label: 'Connected {{PAGES}}', entityTypeName: 'Page', fieldSet: 'connections', recommended: false, description: 'Select other related {{PAGES}} to show as connected to this {{PAGE}}.' };
Page.form.fields.connectedTrends = { type: 'linkedMultiple', label: 'Connected {{TRENDS}}', entityTypeName: 'Trend', fieldSet: 'connections', recommended: false, description: 'Select other related {{TRENDS}} to show as connected to this {{PAGE}}.' };
Page.form.fields.connectedTopics = { type: 'linkedMultiple', label: 'Connected {{TOPICS}}', entityTypeName: 'Topic', fieldSet: 'connections', recommended: false, description: 'Select other related {{TOPICS}} to show as connected to this {{PAGE}}.' };
Page.form.fields.connectedSubTopics = { type: 'linkedMultiple', label: 'Connected {{SUB_TOPICS}}', entityTypeName: 'SubTopic', fieldSet: 'connections', recommended: false, description: 'Select other related {{SUB_TOPICS}} to show as connected to this {{PAGE}}.' };


IndicatorValue.label = 'Indicator Result';

Menu.form.fields.json = {
  type: 'json',
  fieldSet: 'json',
  recommended: false,
  height: "calc(100vh - 280px)",
  description: "Alternatively, specify a JSON object to describe the structure" 
};

Page.components.details = PageDetailsLayout;

Person.form.fields.hideOnSite = {
  type: 'boolean',
  fieldSet: 'visibility',
  recommended: false,
};

FeedEntry.filters.fields = {
  topic: { entityTypeName: 'Topic' },
  subTopic: { entityTypeName: 'SubTopic' },
};


Topic.form.fields.globalExperts = { label: 'Global Experts', fieldSet: 'community', description: 'Who are the global experts for this {{TOPIC}}?', type: 'linkedMultiple', entityTypeName: 'Person' };
Topic.form.fields.indicatorValues = { type: 'linkedTable', entityTypeName: 'IndicatorValue', fieldSet: 'indicators', recommended: false };
Topic.form.fields.trendIntro = { type: 'longText', description: 'Provide a contextual introduction for this {{Topic.label}} when viewed from the {{Trend.label}} page above it' };
Topic.form.fields.pastYearAdvancements = { label: 'Advancements in the Past Year', type: 'longText' };
Topic.form.fields.anticipatoryImpact = { label: 'Anticipatory Impact', type: 'longText' };
Topic.form.fields.anticipatoryImpactImage = { label: 'Anticipatory Impact Image', fieldSet: 'media', description: 'Setup an image asset for the anticipatory impact radar chart for this {{TOPIC}}', type: 'linked', entityTypeName: 'ImageAsset' };
Topic.form.fields.connectedPages = { type: 'linkedMultiple', label: 'Connected {{PAGES}}', entityTypeName: 'Page', fieldSet: 'connections', description: 'Select other related {{PAGES}} to show as connected to this {{TOPIC}}.' };
Topic.form.fields.connectedTrends = { type: 'linkedMultiple', label: 'Connected {{TRENDS}}', entityTypeName: 'Trend', fieldSet: 'connections', description: 'Select other related {{TRENDS}} to show as connected to this {{TOPIC}}.'  };
Topic.form.fields.connectedTopics = { type: 'linkedMultiple', label: 'Connected {{TOPICS}}', entityTypeName: 'Topic', fieldSet: 'connections', description: 'Select other related {{TOPICS}} to show as connected to this {{TOPIC}}.'  };
Topic.form.fields.connectedSubTopics = { type: 'linkedMultiple', label: 'Connected {{SUB_TOPICS}}', entityTypeName: 'SubTopic', fieldSet: 'connections', description: 'Select other related {{SUB_TOPICS}} to show as connected to this {{TOPIC}}.'  };

Topic.form.fieldSets = [
  ...filter(Topic.form.fieldSets, (fs) => !includes(['indicators'], fs.name)),
  { name: 'indicators' },
];


Trend.form.fieldSets = [
  ...filter(Topic.form.fieldSets, (fs) => !includes(['sentiments', 'indicators', 'topics'], fs.name)),
  { name: 'topics' },
  { name: 'sentiments' },
  { name: 'indicators' },
];

Trend.form.fields.sentimentAnalyses = { type: 'linkedTable', entityTypeName: 'SentimentAnalysis', fieldSet: 'sentiments', recommended: false };
Trend.form.fields.indicatorValues = { type: 'linkedTable', entityTypeName: 'IndicatorValue', fieldSet: 'indicators', recommended: false };
Trend.form.fields.dataSetId = { type: 'text', monospace: true, description: "Specify an ID to uniquely identify this {{TREND}}. This might be useful to tie this {{TREND}} to values coming from an external API, for example" };
Trend.form.fields.radarImage = { label: 'Radar Image', description: 'Add the Radar image asset for this {{TREND}}', type: 'linked', entityTypeName: 'ImageAsset' };
Trend.form.fields.connectedPages = { type: 'linkedMultiple', label: 'Connected {{PAGES}}', entityTypeName: 'Page', fieldSet: 'connections', description: 'Select other related {{PAGES}} to show as connected to this {{TREND}}.' };
Trend.form.fields.connectedTrends = { type: 'linkedMultiple', label: 'Connected {{TRENDS}}', entityTypeName: 'Trend', fieldSet: 'connections', description: 'Select other related {{TRENDS}} to show as connected to this {{TREND}}.'  };
Trend.form.fields.connectedTopics = { type: 'linkedMultiple', label: 'Connected {{TOPICS}}', entityTypeName: 'Topic', fieldSet: 'connections', description: 'Select other related {{TOPICS}} to show as connected to this {{TREND}}.'  };
Trend.form.fields.connectedSubTopics = { type: 'linkedMultiple', label: 'Connected {{SUB_TOPICS}}', entityTypeName: 'SubTopic', fieldSet: 'connections', description: 'Select other related {{SUB_TOPICS}} to show as connected to this {{TREND}}.'  };

Trend.form.fields.WhatDoPeopleSayPage = { type: 'linked', label: 'What Do People Say', entityTypeName: 'Page', fieldSet: 'overview', description: 'Select the related What Do People Say {{PAGE}}. This will be shown as a sub-tab within the {{TREND}}' };
Trend.form.fields.WhatDoPeopleDoPage = { type: 'linked', label: 'What Do People Do', entityTypeName: 'Page', fieldSet: 'overview', description: 'Select the related What Do People Do {{PAGE}}. This will be shown as a sub-tab within the {{TREND}}' };
Trend.form.fields.MediaAnalysis = { type: 'linked', label: 'Media Analysis', entityTypeName: 'Page', fieldSet: 'overview', description: 'Select the related Media Analysis {{PAGE}}. This will be shown as a sub-tab within the {{TREND}}' };

SubTopic.form.fields.indicatorValues = { type: 'linkedTable', entityTypeName: 'IndicatorValue', fieldSet: 'indicators', recommended: false };
SubTopic.form.fields.anticipationScores = { type: 'longText', fieldSet: 'anticipation-scores' };
SubTopic.form.fields.anticipationScoresImage = { type: 'linked', entityTypeName: 'ImageAsset', fieldSet: 'anticipation-scores' };


delete FeedEntry.form.fields.offeringType;

if (appSectionConfigs.community) {
  appSectionConfigs.community.children = filter(appSectionConfigs.community.children, (child: any) => !includes(["chapters"], child.id));
}

const myEntityConfigs = { 
  ...entityConfigs,
  SentimentAnalysis,
  SentimentObservation,
  SentimentType,
};

export const application = createApplication({
  theme: themes.OCEAN_THEME,
  entityConfigs: myEntityConfigs,
  appSectionConfigs,
  options: {
    systemsMapping: 'full',
    shapeLogoLabel: 'oceans',
    shapeLogoIconColor: 'primary',
    shapeLogoIcon: Icons.Platforms.Ecosystems,
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});


/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;

delete entities.Challenge;
delete entities.KeyIssue;
delete entities.Sdg;
delete entities.ImpactStory;

// -- ensure that traffic control team is not shown in lists
entities.Team.list.filterItems = (items) => filter(items, item => item.slug !== 'traffic-control');

// -- show Traffic controllers
entities.Team.list.postBanner = <WorkstreamTrafficControl />;
